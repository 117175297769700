<template>
  <div class="box-css">
    <div class="left">
      <van-dropdown-menu active-color="#FF9D20">
        <van-dropdown-item v-model="cityCode" :options="cityList" />
      </van-dropdown-menu>
    </div>
    <div class="right">
      <img class="search-icon" src="@/assets/img/homerec/sousuo.png" alt="" />
      <input
        class="search-input"
        v-model="searchValue"
        type="text"
        placeholder="请输入陵园名称"
      />
      <span @click="clearClick" class="clear-icon" v-show="showIcon"></span>
      <button @click="searchClick" class="search-btn" v-show="showIcon">
        搜索
      </button>
    </div>
  </div>
</template>
<script>
import { getRecommendCitiesApi, getCityCodeApi } from "@/api/apiList.js";
import { mapMutations, mapGetters } from "vuex";
import { Dialog } from 'vant';
export default {
  name: "comSearch",
  props: {
    code: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchValue: "",
      cityList: [],
      cityCode: "",
      loadFlag: true,
    };
  },
  watch: {
    cityCode(code) {
      if (this.code && this.loadFlag) {
        this.loadFlag = false;
        return;
      }
      this.searchValue = "";
      this.setSearch("");
      let cityName = this.cityListX[this.cityListX.findIndex((item)=> item.value == code)].text || '当地'
      this.setCityName(cityName)
      this.$emit("changeCity", code);
    },
  },
  computed: {
    ...mapGetters(["cityListX", "search", "isTip"]),
    showIcon() {
      return this.searchValue;
    },
  },
  async created() {
    if (this.search) {
      this.searchValue = this.search;
    }
    if (this.code) {
      this.cityCode = this.code;
    }
    if (this.cityListX.length > 0) {
      this.cityList = this.cityListX;
    }
    if (this.cityList.length < 1) {
      await this.getRecommendCitiesFun();
      this.setCityListX(this.cityList);
    }
    if (!this.code) {
      await this.getCityCodeFun();
    }
  },
  methods: {
    ...mapMutations(["setCityListX", "setSearch", "setIsTip", "setCityName"]),
    clearClick() {
      this.searchValue = "";
    },
    getRecommendCitiesFun() {
      return new Promise((resolve) => {
        getRecommendCitiesApi().then((res) => {
          let data = res.data?.data || [];
          if (data.length > 0) {
            this.cityList = data.map((item) => {
              return {
                text: item.cityName,
                value: item.cityCode,
              };
            });
          }else {
            this.cityList = [{
              text: "北京",
              value: "1101",
            }]
          }
          resolve();
        });
      });
    },
    getCityCodeFun() {
      getCityCodeApi().then(async (res) => {
        let data = res.data?.data || '北京'
        let index = this.cityList.findIndex((item) =>
          item.text.includes(data)
        );
        let city = null;
        if (index > -1) {
          city = this.cityList[index];
        } else {
          await this.confirm()
          // 只展示一次
          this.setIsTip(true)
          let isHasBj = false;
          for (let i = 0; i < this.cityList.length; i++) {
            const item = this.cityList[i];
            if (item.text.includes("北京")) {
              isHasBj = true;
              break;
            }
          }
          if (isHasBj) {
            city = {
              text: "北京",
              value: "1101",
            };
          } else {
            city = this.cityList[0];
          }
        }
        this.cityCode = city.value;
      });
    },
    searchClick() {
      if (this.searchValue == this.search) {
        return;
      }
      this.setSearch(this.searchValue);
      this.$emit("emit-search", this.searchValue);
    },
    confirm() {
      return new Promise((resolve) => {
        if(this.isTip) {
          resolve()
          return
        }
        Dialog.confirm({
          title: "温馨提示",
          message: "您当前所在地区暂无推荐陵园，可看看其他城市~",
          confirmButtonColor: '#FF9D20',
          messageAlign: 'left'
        })
          .then(() => {
            resolve()
          })
          .catch(() => {
            resolve()
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.box-css /deep/ {
  display: flex;
  width: 100%;
  height: 0.72rem;
  align-items: center;
  justify-content: space-between;
  background: #f7f8f9;
  border-radius: 0.36rem;
  padding: 0.22rem;
  box-sizing: border-box;
  .van-dropdown-menu__bar {
    min-width: 1.1rem;
    // height: 0.3rem;
    background: transparent;
    box-shadow: none;
  }
  .van-dropdown-menu__title::after {
    border-color: transparent transparent #9A9B9C #9A9B9C;
  }
  .van-cell__title, .van-ellipsis {
    font-size: 16px;
  }
  .left {
    display: flex;
    align-items: center;
    min-width: 1.1rem;
    height: 0.3rem;
    padding-right: 0.2rem;
    margin-right: 0.2rem;
    border-right: 1px solid #D8D8D8;
    .name {
      font-size: 0.28rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      padding-right: 0.12rem;
    }
    .iocn {
      width: 0.14rem;
      height: 0.1rem;
      background: url("~@/assets/img/homerec/down-icon.png") no-repeat center;
      background-size: 100%;
    }
  }
  .right {
    width: 0;
    flex: 1;
    display: flex;
    align-items: center;
    .search-icon {
      width: 0.25rem;
      height: 0.28rem;
      margin-right: 0.24rem;
    }
    .search-input {
      width: 0;
      flex: 1;
    }
    .clear-icon {
      width: 0.28rem;
      height: 0.28rem;
      padding: 0.1rem;
      background: url(~@/assets/img/homerec/clear.png) no-repeat center;
      background-size: 0.28rem;
      margin: 0 0.1rem;
    }
    .search-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 0.8rem;
      height: 0.48rem;
      background: #ff9d20;
      border-radius: 0.5rem;
      font-size: 0.24rem;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #f7f8f9;
      cursor: pointer;
    }
  }
}
</style>